var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model_main",class:_vm.modelType.class},[(_vm.treeData.length > 0)?_c('a-tree',{staticClass:"model-tree",attrs:{"defaultExpandAll":"","tree-data":_vm.treeData,"show-icon":false,"replace-fields":_vm.replaceFields},scopedSlots:_vm._u([{key:"title",fn:function({
                label,
                Content,
                levelTit,
                type,
                card,
                IsCollection,
                num,
            }){return [(_vm.modelType.ids.includes(Number(type)))?_c('div',{staticClass:"tree_title type0"},[_c('div',{staticClass:"tree_title_header",style:('background-color:' +
                            _vm.cardType.find(item => item.key == type).color +
                            '33' || +';'),attrs:{"id":_vm.modelType.class + card.Id},on:{"click":function($event){return _vm.goCard(card, num)}}},[_c('div',{staticClass:"cardName",style:('background-color:' +
                                _vm.cardType.find(item => item.key == type)
                                    .color || +';')},[_vm._v(" "+_vm._s(_vm.cardType.find(item => item.key == type).value)+" ")]),_c('div',[_c('span',{staticClass:"level"},[_vm._v(_vm._s(levelTit))]),_c('span',{staticClass:"title",domProps:{"innerHTML":_vm._s(label)}})])]),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[(IsCollection)?_c('span',[_vm._v("取消收藏")]):_c('span',[_vm._v("收藏")])]),_c('svg-icon',{class:IsCollection ? 'active' : '',attrs:{"icon-class":"icon_collect"},nativeOn:{"click":function($event){return _vm.toggleCollect(IsCollection, card)}}})],2)],1):_vm._e(),(_vm.modelType.ids.includes(Number(type)))?_c('InlineEditor',{key:num,attrs:{"id":card.Id,"data":Content,"isEditable":_vm.isEditable,"keyword":_vm.keyword,"selectItem":_vm.noteItem},on:{"feedback":_vm.addFeedback,"notes":_vm.addNotes,"remark":_vm.showRemark}}):_vm._e()]}}],null,false,2620399793)}):_vm._e(),(_vm.data.length == 0)?_c('a-empty'):_vm._e(),_c('RemarkPop',{attrs:{"visable":_vm.popVisible,"positon":_vm.popPos,"id":_vm.remarkId},on:{"close":_vm.closeRemark}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }