<template>
    <div class="jsmind" :class="fullScreen && 'full'">
        <div class="toolbar">
            <ul class="toolbar-list">
                <li class="item">
                    <span
                        size="16"
                        class="percent"
                        style="font-size: 14px; color: rgb(29, 29, 31)"
                        @click="value = 100"
                        >{{ value }}%
                    </span>
                    <div class="scale-slide">
                        <ul>
                            <li
                                class="normal-item"
                                title="缩小"
                                @click="zoomOut"
                            >
                                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.98 11.95l2.91 2.9c.15.15.15.38 0 .53l-.51.51a.37.37 0 01-.52 0L11.95 13a7.32 7.32 0 111.03-1.03zm-5.66 1.22a5.85 5.85 0 100-11.7 5.85 5.85 0 000 11.7zM4.76 6.6h5.12c.2 0 .37.16.37.36v.73c0 .2-.17.37-.37.37H4.76a.37.37 0 01-.37-.37v-.73c0-.2.16-.36.37-.36z"
                                        fill="#2B2F36"
                                        fill-rule="nonzero"
                                    ></path>
                                </svg>
                            </li>
                            <li class="slider">
                                <!-- <el-slider
                                    v-model="value"
                                    :show-tooltip="false"
                                    :min="min"
                                    :max="max"
                                ></el-slider> -->
                                <a-slider  v-model="value" :max="max" :min="min"/>
                            </li>
                            <li
                                class="normal-item"
                                title="画布放大"
                                @click="zoomIn"
                            >
                                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.98 11.95l2.91 2.9c.15.15.15.38 0 .53l-.51.51a.37.37 0 01-.52 0L11.95 13a7.32 7.32 0 111.03-1.03zm-6.4-5.36V4.76c0-.2.17-.37.37-.37h.73c.2 0 .37.16.37.37v1.83h1.83c.2 0 .37.16.37.36v.73c0 .2-.17.37-.37.37H8.05v1.83c0 .2-.16.37-.37.37h-.73a.37.37 0 01-.36-.37V8.05H4.76a.37.37 0 01-.37-.37v-.73c0-.2.16-.36.37-.36h1.83zm.74 6.58a5.85 5.85 0 100-11.7 5.85 5.85 0 000 11.7z"
                                        fill="#2B2F36"
                                        fill-rule="nonzero"
                                    ></path>
                                </svg>
                            </li>
                        </ul>
                    </div>
                </li>

                <div class="layout item">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                        <path
                            d="M12.56 4.06c1.41 0 2.8.28 4.05.8l-.99 1.21a9.3 9.3 0 00-2.67-.5l-.4-.01h-.43a9.14 9.14 0 00-5.28 2c-.72.61-1.3 1.32-1.69 2.09a5.1 5.1 0 000 4.82c.39.74.95 1.41 1.68 2 .73.59 1.59 1.06 2.55 1.39a9.75 9.75 0 004.5.42l.44-.06.53-.11.06-.02c.07-.03.14-.1.2-.2a.8.8 0 00.1-.47l-.03-.12-.03-.1-.05-.22a3.32 3.32 0 01.46-2.53c.52-.8 1.36-1.3 2.27-1.36H19.45l.1-.01c.48-.07.87-.53.96-1.1l.02-.14v-.25l-.02-.22-.05-.29a5.6 5.6 0 00-1.13-2.4l.96-1.2a7.08 7.08 0 011.63 3.34l.06.34.04.34v.37c-.08 1.45-1.12 2.6-2.4 2.7H18.02c-.48 0-.94.26-1.23.7-.22.34-.32.75-.28 1.16l.02.18.07.27a2.3 2.3 0 01-.2 1.75c-.24.41-.6.72-1 .86l-.17.05-.57.12c-.42.08-.84.13-1.26.16l-.42.03h-.44c-1.26 0-2.5-.2-3.65-.6a9.85 9.85 0 01-3-1.63 7.91 7.91 0 01-2.07-2.47 6.58 6.58 0 010-6.19A8.24 8.24 0 015.9 6.42a9.85 9.85 0 013-1.72c.92-.32 1.86-.53 2.81-.6l.41-.02.43-.01h.02zM7.4 12.4a1.1 1.1 0 110 2.2 1.1 1.1 0 010-2.2zm12.29-9.01a.5.5 0 01.63-.13l.07.05.4.31c.18.16.23.42.12.63l-.05.08-7.69 9.6-1.14.51a.2.2 0 01-.28-.17v-.05L12 13l7.69-9.61zM8.4 8.4a1.1 1.1 0 110 2.2 1.1 1.1 0 010-2.2zm4.2-1.5a1.1 1.1 0 110 2.2 1.1 1.1 0 010-2.2z"
                            fill="#2B2F36"
                            fill-rule="evenodd"
                        ></path>
                    </svg>
                    <div class="structure">
                        <div class="tab-content">
                            <h3 class="tab-label">结构</h3>
                            <ul class="struct-list">
                                <li @click="toggleStucture('side')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" >
                                        <g fill="none" fill-rule="evenodd">
                                            <path
                                                :fill="pathColor('side')"
                                                d="M18.77 15.25H22a.75.75 0 110 1.5h-4c1.05.97 1.9 3.27 2.76 7.02.4 1.73.88 2.48 1.24 2.48a.75.75 0 110 1.5c-1.33 0-2.16-1.29-2.7-3.65-.87-3.77-1.76-5.93-2.38-6.32A2 2 0 0116 18c-.35 0-.68-.09-.97-.25H15c-.36 0-.84.75-1.24 2.48-1.07 4.66-2.13 7.1-3.56 7.5a.75.75 0 11-.4-1.45c.62-.18 1.58-2.37 2.5-6.38.34-1.5.8-2.57 1.44-3.15H10a.75.75 0 110-1.5h3.02a9.55 9.55 0 01-.72-2.15c-.92-4.01-1.88-6.2-2.5-6.38a.75.75 0 11.4-1.44c1.43.4 2.5 2.83 3.56 7.49.21.93.45 1.57.68 1.97A2 2 0 0116 14a2 2 0 011.43.6c.55-.95 1.22-2.87 1.87-5.7.54-2.36 1.37-3.65 2.7-3.65a.75.75 0 110 1.5c-.36 0-.84.75-1.24 2.48-.64 2.8-1.28 4.79-2 6.02z"
                                            ></path>
                                            <rect  width="5" height="3" x="26" y="5" :fill="rectColor('side')" rx=".5" ></rect>
                                            <rect width="5" height="3" x="26" y="15" :fill="rectColor('side')" rx=".5" ref=""></rect>
                                            <rect width="5" height="3" x="26" y="25" :fill="rectColor('side')" rx=".5" ></rect>
                                            <rect width="5" height="3" x="1" y="5" :fill="rectColor('side')" rx=".5" ></rect>
                                            <rect width="5" height="3" x="1" y="15" :fill="rectColor('side')" rx=".5" ></rect>
                                            <rect width="5" height="3" x="1" y="25" :fill="rectColor('side')" rx=".5" ></rect>
                                        </g>
                                    </svg>
                                </li>

                                <li class="" @click="toggleStucture('right')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                        <g fill="none" fill-rule="evenodd" transform="translate(2 4)">
                                            <path
                                                :fill="pathColor('right')"
                                                d="M4.92 11.25H9.5a.75.75 0 110 1.5H4.92c.87 1.34 1.66 3.56 2.44 6.7.5 1.94 1.1 2.8 1.64 2.8a.75.75 0 110 1.5c-1.48 0-2.45-1.39-3.1-3.94-.82-3.33-1.67-5.5-2.4-6.5a2 2 0 110-2.63c.73-.98 1.58-3.16 2.4-6.49C6.56 1.64 7.53.25 9 .25a.75.75 0 010 1.5c-.55 0-1.15.86-1.64 2.8-.78 3.14-1.57 5.36-2.44 6.7z"
                                            ></path>
                                            <rect width="12" height="3" x="14" :fill="rectColor('right')" rx=".5"></rect>
                                            <rect width="12" height="3" x="14" y="10.5" :fill="rectColor('right')" rx=".5"></rect>
                                            <rect width="12" height="3" x="14" y="21" :fill="rectColor('right')" rx=".5"></rect>
                                        </g>
                                    </svg>
                                </li>
                                <li class="" @click="toggleStucture('left')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                        <g fill="none" fill-rule="evenodd" transform="matrix(-1 0 0 1 29 4)">
                                            <path
                                                :fill="pathColor('left')"
                                                d="M4.92 11.25H9.5a.75.75 0 110 1.5H4.92c.87 1.34 1.66 3.56 2.44 6.7.5 1.94 1.1 2.8 1.64 2.8a.75.75 0 110 1.5c-1.48 0-2.45-1.39-3.1-3.94-.82-3.33-1.67-5.5-2.4-6.5a2 2 0 110-2.63c.73-.98 1.58-3.16 2.4-6.49C6.56 1.64 7.53.25 9 .25a.75.75 0 010 1.5c-.55 0-1.15.86-1.64 2.8-.78 3.14-1.57 5.36-2.44 6.7z"
                                            ></path>
                                            <rect width="12" height="3" x="14" :fill="rectColor('left')" rx=".5"></rect>
                                            <rect width="12" height="3" x="14" y="10.5" :fill="rectColor('left')" rx=".5"></rect>
                                            <rect width="12" height="3" x="14" y="21" :fill="rectColor('left')" rx=".5" ></rect>
                                        </g>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    class="reset item normal-item"
                    title="定位到中心主题"
                    @click="reset"
                    style="margin-bottom:.16rem;"
                >
                    <svg t="1658569094322" width="20" height="20" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3045" >
                        <path d="M512 512m-80 0a80 80 0 1 0 160 0 80 80 0 1 0-160 0Z" p-id="3046"></path>
                        <path d="M960 480h-33.632C910.752 276.16 747.84 113.248 544 97.632V64a32 32 0 1 0-64 0v33.632C276.16 113.248 113.248 276.16 97.632 480H64a32 32 0 0 0 0 64h33.632C113.248 747.84 276.16 910.752 480 926.368V960a32 32 0 1 0 64 0v-33.632C747.84 910.752 910.752 747.84 926.368 544H960a32 32 0 1 0 0-64zM544 862.368V800a32 32 0 1 0-64 0v62.368C311.424 847.104 176.896 712.576 161.632 544H224a32 32 0 1 0 0-64H161.632C176.896 311.424 311.424 176.896 480 161.632V224a32 32 0 0 0 64 0V161.632c168.576 15.296 303.104 149.792 318.368 318.368H800a32 32 0 1 0 0 64h62.368c-15.264 168.576-149.792 303.104-318.368 318.368z" p-id="3047"></path>
                    </svg>
                </div>
                <div
                    class="reset item normal-item"
                    :title="fullScreen?'取消全屏':'全屏'"
                    @click="showfullScreen"
                >
                    <svg t="1658569244785" width="20" height="20" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3976" >
                        <path d="M368.896 192H224a32 32 0 0 0-32 32v137.888a32 32 0 0 0 64 0V256h112.896a32 32 0 0 0 0-64zM784.864 192H640a32 32 0 1 0 0 64h112.864v105.888a32 32 0 1 0 64 0V224a32 32 0 0 0-32-32zM368.896 777.92H256V672a32 32 0 1 0-64 0v137.92a32 32 0 0 0 32 32h144.896a32 32 0 1 0 0-64zM784.864 640a32 32 0 0 0-32 32v105.92H640a32 32 0 1 0 0 64h144.864a32 32 0 0 0 32-32V672a32 32 0 0 0-32-32z" p-id="3977"></path>
                        <path d="M912 48h-800c-35.296 0-64 28.704-64 64v800c0 35.296 28.704 64 64 64h800c35.296 0 64-28.704 64-64v-800c0-35.296-28.704-64-64-64z m-800 864v-800h800l0.064 800H112z" p-id="3978"></path>
                    </svg>
                </div>
            </ul>
        </div>
        <div class="jsmind_layout">
            <div
                :id="modelType.class + 'jsmind_container'"
                class="jsmind_container"
                ref="container"
                @click="nodeClick"
                @contextmenu.prevent.stop="nodeClick"
            ></div>
        </div>
    </div>
</template>
<script>
import { addImageWaterMask } from '@/assets/js/common'
export default {
    props: ['modelType', 'data', 'rootName'],
    data() {
        return {
            uid: '',
            mind: {
                meta: {
                    name: '思维导图',
                    author: 'hizzgdev@163.com',
                    version: '0.2',
                },
                format: 'node_tree',
                data: {
                    id: 'root',
                    topic: 'jsMind',
                    children: [],
                },
            },
            options: {
                container: '', // [必选] 容器的ID
                editable: true, // [可选] 是否启用编辑
                theme: '', // [可选] 主题
                view: {
                    engine: 'canvas', // 思维导图各节点之间线条的绘制引擎
                    hmargin: 120, // 思维导图距容器外框的最小水平距离
                    vmargin: 50, // 思维导图距容器外框的最小垂直距离
                    line_width: 2, // 思维导图线条的粗细
                    line_color: '#ddd', // 思维导图线条的颜色
                },
                layout: {
                    hspace: 100, // 节点之间的水平间距
                    vspace: 20, // 节点之间的垂直间距
                    pspace: 20, // 节点与连接线之间的水平间距（用于容纳节点收缩/展开控制器）
                },
                menuOpts: {
                    // 这里加入一个专门配置menu的对象
                    showMenu: true, // showMenu 为 true 则打开右键功能 ，反之关闭
                    injectionList: [{ target: 'showCard', text: '查看卡片' }],
                    style: {
                        menuItem: {
                            'line-height': '28px',
                        },
                    },
                },
                shortcut: {
                    enable: false,
                },
            },
            editable: false,
            value: 100, // 层级大小
            min: 10, // 最小层级
            max: 400, // 最大层级
            bgMap: {
                0: {
                    original: 'rgb(0, 21, 41)',
                    transparent: 'rgb(0, 21, 41, 0.2)',
                },
                1: {
                    original: 'rgb(254, 230, 230)',
                    transparent: 'rgb(254, 230, 230), 0.2)',
                },
                2: {
                    original: 'rgb(238, 249, 233)',
                    transparent: 'rgb(238, 249, 233, 0.2)',
                },
                3: {
                    original: 'rgb(236, 234, 247)',
                    transparent: 'rgb(236, 234, 247, 0.2)',
                },
                4: {
                    original: 'rgb(232, 244, 255)',
                    transparent: 'rgb(232, 244, 255, 0.2)',
                },
            },
            structure: {
                active: 'right',
                pathOrginalColor: '#2B2F36',
                pathActiveColor: '#3370FF',
                rectOrginalColor: '#C3C6CB',
                rectActiveColor: '#BACEFD'
            },
            fullScreen:false
        }
    },
    computed: {
        pathColor () {
            return function (type) {
                return this.structure.active === type ? this.structure.pathActiveColor : this.structure.pathOrginalColor
            }
        },
        rectColor () {
            return function (type) {
                return this.structure.active === type ? this.structure.rectActiveColor : this.structure.rectOrginalColor
            }
        }
    },
    watch: {
        value (val) {
            const zoom = val / 100
            this.jm.view.setZoom(zoom)
        },
        // data: {
        //     handler(newVal, oldVal) {
        //         if (!newVal) return
        //         if (this.jm) {
        //             this.mind.data.children = newVal
        //             this.loopTreeData(this.mind.data.children, (item) => {
        //                 item.id = this.uid + item.id
        //             })
        //             this.jm.show(this.mind)
        //             this.init()
        //         }
        //     },
        //     immediate: true,
        // },
    },
    methods: {
        // 初始化配置
        init() {
            this.jm.view.minZoom = 0.1
            this.jm.view.maxZoom = 5
            this.jm.expand_all()
            this.jm.enable_edit()
            this.setColor()
            !this.editable && this.jm.disable_edit()

            // 禁用双击编辑
            // this.jm.disable_event_handle('dblclick')
            this.jm.view.edit_node_end = () => {
                const node = this.jm.view.get_editing_node()
                const viewData = node._data.view
                const element = viewData.element
                element.style.zIndex = 'auto'
                const card = node.data.card
                card.Name = this.editor.value
                const treeNum = node.data.num
                if (!card.Name) {
                    this.$message.info('请输入卡片标题')
                    this.jm.update_node(node.id, node.topic)
                    return
                }
                if (card.Name === node.topic) {
                    this.jm.update_node(node.id, node.topic)
                    return
                }
                const req = {
                    cardId: card.Id,
                    title: card.Name,
                    modelId: this.$route.query.id,
                    treeNum,
                }
                // 修改节点
                SaveTitle(Base64.encode(JSON.stringify(req)))
                    .then((res) => {
                        this.$emit('update')
                        res.data &&
                            res.data.Name &&
                            this.jm.update_node(node.id, res.data.Name)
                    })
                    .catch((err) => {
                        console.log(err)
                        this.jm.update_node(node.id, node.topic)
                    })
            }

            // 重写设置层级方法
            // this.jm.view.setZoom = (zoom) => {
            //   if ((zoom < this.jm.view.minZoom) || (zoom > this.jm.view.maxZoom)) {
            //     return false;
            //   }
            //   this.jm.view.actualZoom = zoom;
            //   for (var i=0; i < this.jm.view.e_panel.children.length; i++) {
            //     this.jm.view.e_panel.children[i].style.zoom = zoom;
            //   };
            //   // this.jm.show(true);
            //   return true;
            // }
        },
        // 设置背景颜色
        setColor() {
            this.jm.set_node_color(this.uid+ 'root', this.bgMap[4].original, '#fff')
            this.loopTreeData(this.mind.data.children, (item) => {
                this.jm.set_node_color(
                    item.id,
                    this.bgMap[item.type].original,
                    '#fff'
                )
                // if (this.curFilter.length) {
                // if (this.curFilter.includes(item.type)) {
                //     this.jm.set_node_color(item.id, this.bgMap[item.type].original, '#fff')
                // } else {
                //     this.jm.set_node_color(item.id, this.bgMap[item.type].transparent, '#fff')
                // }
                // } else {
                // this.jm.set_node_color(item.id, this.bgMap[item.type].transparent, '#fff')
                // }
            })
        },
        // 获取选中标签的 ID
        get_selected_nodeid() {
            const selectedNode = this.jm.get_selected_node()
            if (selectedNode) {
                return selectedNode.id
            } else {
                return null
            }
        },
        // 单击重置选中背景颜色
        nodeClick() {
            const selectedId = this.get_selected_nodeid()
            if (!selectedId) return
            this.jm.enable_edit()
            const nodeObj = this.jm.get_node(selectedId)
            this.jm.set_node_color(
                selectedId,
                nodeObj.data['background-color'],
                '#fff'
            )
            !this.editable && this.jm.disable_edit()
        },
        // 鼠标拖拽
        mouseDrag() {
            // 里层
            const el = $(
                '#' + this.modelType.class + 'jsmind_container .jsmind-inner'
            )[0]
            // 外层
            const outWrapperEl = document.querySelector('.model_info')
            // 选中节点
            let selected

            el.onmousedown = (ev) => {
                // 选中节点
                selected = this.jm.get_selected_node()
                // if (selected && selected.parent && this.editable) {
                //   this.dragNodeFlag = true
                // }
                this.dragNodeFlag = !!selected
                const disX = ev.clientX
                const disY = ev.clientY
                const originalScrollLeft = el.scrollLeft
                const originalScrollTop = el.scrollTop
                const outWrapperScrollLeft = outWrapperEl.scrollLeft
                const outWrapperScrollTop = outWrapperEl.scrollTop
                const originalScrollBehavior = el.style['scroll-behavior']
                const originalPointerEvents = el.style['pointer-events']
                // auto: 默认值，表示滚动框立即滚动到指定位置。
                el.style['scroll-behavior'] = 'auto'
                // el.style.cursor = 'grabbing'
                // 鼠标移动事件是监听的整个document，这样可以使鼠标能够在元素外部移动的时候也能实现拖动
                document.onmousemove = (ev) => {
                    if (this.dragNodeFlag) return
                    this.drag = false
                    ev.preventDefault()
                    // 计算拖拽的偏移距离
                    const distanceX = ev.clientX - disX
                    const distanceY = ev.clientY - disY

                    el.scrollTo(
                        originalScrollLeft - distanceX,
                        originalScrollTop - distanceY
                    )

                    // 非全屏下滑动到最顶或最底部 控制外层滚动条
                    if (!this.fullScreen) {
                        if (
                            (el.scrollTop === 0 &&
                                el.scrollHeight > el.clientHeight) ||
                            el.scrollTop + el.clientHeight === el.scrollHeight
                        ) {
                            // 触底
                            outWrapperEl.scrollTo(
                                outWrapperScrollLeft - distanceX,
                                outWrapperScrollTop - distanceY
                            )
                        }
                    }

                    // 在鼠标拖动的时候将点击事件屏蔽掉
                    el.style['pointer-events'] = 'none'
                    el.style.cursor = 'grabbing'
                }
                document.onmouseup = () => {
                    if (!this.dragNodeFlag) {
                        el.style['scroll-behavior'] = originalScrollBehavior
                        el.style['pointer-events'] = originalPointerEvents
                        el.style.cursor = 'grab'
                    }
                    document.onmousemove = document.onmouseup = null
                }
            }
        },
        // 重置视图
        reset () {
            const dom = $('jmnode[nodeid=' + this.uid + 'root]')[0]
            if(!dom) return
            $('jmnode[nodeid=' + this.uid + 'root]')[0].scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            })
        },
        // 缩小
        zoomOut () {
            this.jm.view.zoomOut()
            this.value = parseInt(this.jm.view.actualZoom * 100)
        },
        // 放大
        zoomIn () {
            this.jm.view.zoomIn()
            this.value = parseInt(this.jm.view.actualZoom * 100)
        },
        // 切换思维导图结构
        toggleStucture (type) {
            if (this.structure.active === type) return
            this.structure.active = type
            switch (type) {
                case 'side':
                    this.loopTreeData(this.mind.data.children, (item, i) => { item.direction = i % 2 ? 'left' : 'right' })
                    break

                case 'left':
                    this.loopTreeData(this.mind.data.children, (item) => { item.direction = 'left' })
                    break

                case 'right':
                    this.loopTreeData(this.mind.data.children, (item) => { item.direction = 'right' })
                    break
        
                default:
                break
            }
            this.jm.show(this.mind)
            this.init()
        },
        
        // 导出图片
        screen_shot () {
            this.jm.enable_edit()
            // 去除透明度
            this.loopTreeData(this.mind.data.children, (item) => this.jm.set_node_color(item.id, this.bgMap[item.type].original, '#fff'))
            try {
                this.jm.screenshot.shootAsDataURL((img) => {
                    let config = {
                        Name:this.$store.getters.userInfo.LoginName,
                        Company:'文物保护工程复习资料库'
                    }
                    addImageWaterMask(img,config).then(res => {
                        var reg1 = /<\/?.+?>/;
                        var reg2 = /（\/?.+?）/;
                        var txt = this.rootName.replace(reg2,"");
                        txt = txt.replace(reg2,"")
                        var anchor = document.createElement('a')
                        anchor.href = res
                        anchor.download = txt
                        document.body.appendChild(anchor);
                        var evt = document.createEvent('MouseEvents');
                        evt.initEvent('click', true, true);
                        anchor.dispatchEvent(evt);
                        document.body.removeChild(anchor);
                    })
                })
                // this.jm.screenshot.shootDownload()
            } catch (error) {
                console.log(error)
            }
            setTimeout(() => {
                this.setColor()
                !this.editable && this.jm.disable_edit()
            }, 1000)
        },
        
        // 全屏
        showfullScreen () {
            this.fullScreen = !this.fullScreen
            // this.$emit('mindFull', this.fullScreen)
        },
        // 鼠标滚轮放大缩小
        mouseWheel () {
            if (document.addEventListener) {
                document.addEventListener('keydown',function(e){
                    if(e.ctrlKey){
                        document.addEventListener('domMouseScroll', this.scrollFunc, false)
                    }
                },false)
                    document.addEventListener('keyup',function(e){
                    document.removeEventListener('domMouseScroll', this.scrollFunc, false)
                },false)
            }
            this.$refs.container.onmousewheel = this.scrollFunc
        },
        // 滚轮缩放
        scrollFunc (e) {
            e = e || window.event
            if(e.ctrlKey){
                if (e.wheelDelta) {
                    if (e.wheelDelta > 0) {
                        this.zoomIn()
                    } else {
                        this.zoomOut()
                    }
                } else if (e.detail) {
                    if (e.detail > 0) {
                        this.zoomIn()
                    } else {
                        this.zoomOut()
                    }
                }
                e.preventDefault()
                this.jm.resize()
            }else{
                return
            }
        },
        // 循环树结构
        loopTreeData(list, callback) {
            ;(function doOneFloor(list) {
                if (Array.isArray(list)) {
                    for (let i = 0; i < list.length; i++) {
                        const item = list[i]
                        callback(item, i)
                        if (item.children.length > 0) {
                            doOneFloor(item.children)
                        }
                    }
                }
            })(list)
        },
        // 生成唯一ID
        getUid() {
            const id = Number(
                Math.random().toString().substr(3, 3) + Date.now()
            ).toString(36)
            return id
        },
    },

    created() {
        this.uid = this.getUid()
        const data = {
            id: this.uid + 'root',
            topic: this.rootName,
            children: this.data,
        }
        this.mind.meta.name = this.rootName + '-思维导图'
        this.mind.data = data
        this.options.container = this.modelType.class + 'jsmind_container'
    },
    mounted() {
        this.loopTreeData(this.mind.data.children, (item) => {
            item.id = this.uid + item.card.Id
        })
        this.jm = jsMind.show(this.options, this.mind)
        this.init()
        this.mouseWheel()
        this.mouseDrag()
    },
    beforeDestroy () {
        document.removeEventListener('domMouseScroll', this.scrollFunc, false)
    }
}
</script>
<style scoped lang="less">
:deep(jmexpander) {
    line-height: 6px;
    margin-top: 8px;
    margin-left: 8px;
}
.jsmind {
    height: 100%;
    position: relative;
    &.full {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 10;
    }
    .jsmind_container {
        flex: 1;
    }
    :deep(.jsmind-inner) {
        cursor: grab;
        overflow: auto;
        // overflow: hidden auto !important;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #e5e5e5;
        }
        &::-webkit-scrollbar-thumb {
            // width: 100%;
            border-radius: 3px;
            background-color: #f6a75c;
        }
    }
    .toolbar {
        float: right;
        z-index: 2;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 99;
        border: 1px solid #dee0e3;
        background-color: #fff;
        border-radius: 5px;
        // -webkit-box-shadow: 0 0 8px 4px rgb(31 , 35 , 41 , .06);
        // box-shadow: 0 0 8px 4px rgb(31 , 35 , 41 , .06);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-align: center;

        .toolbar-list {
            padding: 6px 0;

            .item {
                position: relative;
                width: 48px;
                height: 36px;
                cursor: pointer;
                font-size: 14px;
                line-height: 36px;
                text-align: center;
                margin-bottom: 12px;

                svg {
                    vertical-align: middle;
                }

                &.expand {
                    span {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        // background: url('../../assets/img/main/model/expand.png') center
                        // center / 17px auto no-repeat;
                    }
                }

                .percent {
                    color: rgb(29, 29, 31);
                    line-height: 1.45;
                }

                &:hover {
                    .scale-slide {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                    &.layout {
                    &:hover {
                        .structure {
                        opacity: 1;
                        visibility: visible;
                        }
                    }
                }

                .scale-slide {
                    position: absolute;
                    top: -7px;
                    right: 58px;
                    overflow: hidden;
                    width: 244px;
                    height: 44px;
                    opacity: 1;
                    -webkit-transition: all 0.1s linear;
                    transition: all 0.1s linear;
                    opacity: 0;
                    visibility: hidden;

                    &:hover {
                        opacity: 1;
                        visibility: visible;
                    }

                    ul {
                        position: absolute;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        width: 244px;
                        height: 44px;
                        padding: 4px 8px;
                        border: 1px solid #dee0e3;
                        background-color: #fff;
                        border-radius: 5px;
                        -webkit-box-shadow: 0 0 8px 4px rgb(31 , 35 , 41 , .06);
                        box-shadow: 0 0 8px 4px rgb(31 , 35 , 41 , .06);
                        list-style: none;

                        .normal-item {
                            display: flex;
                            width: 36px;
                            height: 36px;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            cursor: pointer;
                        }

                        .slider {
                            width: 100%;
                            :deep(.el-slider__runway) {
                                height: 2px;
                                background-color: #bbbfc4;

                                .el-slider__bar {
                                    background: none;
                                }

                                .el-slider__button {
                                    border: 0.5px solid #dee0e3;
                                }
                            }
                        }

                        .style__zoom-scroll-bar___3n1YHZLZ {
                            display: flex;
                            .style__zoom-bar___rp3v2Kdv {
                                position: relative;
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                width: 120px;
                                height: 36px;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                cursor: pointer;

                                .style__process-bar___2nCsCTk0 {
                                    position: relative;
                                    left: 50%;
                                    width: 104px;
                                    height: 2px;
                                    -ms-flex-item-align: center;
                                    align-self: center;
                                    margin-left: -52px;
                                    background-color: #bbbfc4;
                                    border-radius: 2px;
                                }

                                .style__process-btn___azlkuc9i {
                                    position: absolute;
                                    width: 16px;
                                    height: 16px;
                                    border: 0.5px solid #dee0e3;
                                    background-color: #fff;
                                    border-radius: 8px;
                                    -webkit-box-shadow: 0 2px 3px 0 rgb(0 , 0 , 0 , .15);
                                    box-shadow: 0 2px 3px 0 rgb(0 , 0 , 0 , .15);
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .structure {
                    position: absolute;
                    top: 0;
                    right: 58px;
                    width: 244px;
                    min-height: 120px;
                    max-height: calc(100vh - 200px);
                    cursor: default;
                    opacity: 0;
                    overflow-y: overlay;
                    -webkit-transition: all 0.1s linear;
                    transition: all 0.1s linear;
                    visibility: hidden;
                    z-index: 1000;
                    &:hover {
                        opacity: 1;
                        visibility: visible;
                    }

                    .tab-content {
                        padding: 24px 24px 10px;
                        border: 1px solid #dee0e3;
                        background-color: #fff;
                        border-radius: 5px;
                        -webkit-box-shadow: 0 0 8px 4px rgb(31 , 35 , 41 , .06);
                        box-shadow: 0 0 8px 4px rgb(31 , 35 , 41 , .06);

                        .tab-label {
                            margin-bottom: 12px;
                            color: #1f2329;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 1.5;
                            text-align: left;
                        }
                        .struct-list {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            -webkit-box-direction: normal;
                            -ms-flex-direction: row;
                            flex-direction: row;
                            -webkit-box-pack: justify;
                            -ms-flex-pack: justify;
                            justify-content: space-between;
                            margin: 0 0 20px;
                            list-style: none;
                            li {
                                width: 32px;
                                height: 32px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .reset {
                margin-bottom: 0;
            }
        }
    }
}
.jsmind_layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: calc(100% - 40px);
    height: 100%;
    // height: 1500px;
    // overflow-x: hidden;
    .jsmind_toolbar {
        width: 100%;
        padding: 0 10px 10px 10px;
        height: auto;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: #f8f9fa;
        box-shadow: 0 0 4px #b8b8b8;
    }
    :deep(.el-button--medium),
    :deep(.el-input--medium) {
        margin-top: 10px;
    }
    #jsmind_container {
        flex: 1 1 auto;
    }
    :deep(.el-upload-list) {
        display: none !important;
    }
    /* 隐藏滚动条 */
    .jsmind-inner::-webkit-scrollbar {
        // display: none;
        // height: 5px;
    }
    .pad {
        margin-right: 10px;
    }
    .pad-left {
        margin-left: 10px;
    }

    :deep(jmnode) {
        max-width: unset;
        background-color: #ddd;
        box-shadow: unset;
        color: #666 !important;
        transition: 0s !important;
        &.selected {
            // background: none !important;
            color: #666 !important;
            border: 1px solid #777;
            transition: 1s;
        }
        :deep(jmnode.selected)  {
            background-color: #b9b9b9;
            color: #fff;
            box-shadow: 2px 2px 8px #777;
        }
        :deep(jmnode:hover)  {
            box-shadow: 2px 2px 8px #777;
        }
    }

    :deep(jmnode.selected)  {
        // background-color: #b9b9b9;
        // color: #fff;
        border: 0;
        box-shadow: 2px 2px 8px #777;
    }
    :deep(jmnode:hover) {
        box-shadow: 2px 2px 8px #777;
    }
}
</style>