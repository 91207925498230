<template>
    <div class="model_main" :class="modelType.class">
        <a-tree
            class="model-tree"
            defaultExpandAll
            :tree-data="treeData"
            :show-icon="false"
            :replace-fields="replaceFields"
            v-if="treeData.length > 0"
        >
            <template
                slot="title"
                slot-scope="{
                    label,
                    Content,
                    levelTit,
                    type,
                    card,
                    IsCollection,
                    num,
                }"
            >
                <div
                    class="tree_title type0"
                    v-if="modelType.ids.includes(Number(type))"
                >
                    <!-- 卡片标题 -->
                    <div
                        class="tree_title_header"
                        @click="goCard(card, num)"
                        :id="modelType.class + card.Id"
                        :style="
                            'background-color:' +
                                cardType.find(item => item.key == type).color +
                                '33' || +';'
                        "
                    >
                        <div
                            class="cardName"
                            :style="
                                'background-color:' +
                                    cardType.find(item => item.key == type)
                                        .color || +';'
                            "
                        >
                            {{ cardType.find(item => item.key == type).value }}
                        </div>
                        <div>
                            <span class="level">{{ levelTit }}</span>
                            <span class="title" v-html="label"></span>
                        </div>
                    </div>
                    <!-- 收藏卡片 -->
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span v-if="IsCollection">取消收藏</span>
                            <span v-else>收藏</span>
                        </template>
                        <svg-icon
                            icon-class="icon_collect"
                            :class="IsCollection ? 'active' : ''"
                            @click.native="toggleCollect(IsCollection, card)"
                        ></svg-icon>
                    </a-tooltip>
                </div>
                <!-- 卡片内容 -->
                <InlineEditor
                    :id="card.Id"
                    :data="Content"
                    @feedback="addFeedback"
                    @notes="addNotes"
                    @remark="showRemark"
                    :key="num"
                    :isEditable="isEditable"
                    :keyword="keyword"
                    v-if="modelType.ids.includes(Number(type))"
                    :selectItem="noteItem"
                />
            </template>
        </a-tree>
        <a-empty v-if="data.length == 0" />

        <!-- 批注弹窗 -->
        <RemarkPop
            :visable="popVisible"
            @close="closeRemark"
            :positon="popPos"
            :id="remarkId"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import InlineEditor from '~/components/common/InlineEditor';
import RemarkPop from '~/components/common/RemarkPop';
import NotesDialog from '~/components/Learning/Model/NotesDialog';

import { ToggleCollectCard } from '@/api/collection';
export default {
    // props:['modelType','data'],
    props: {
        modelType: Object,
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
        keyword: String,
        noteItem: {
            type: Object,
            default: {},
        },
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                console.log(newVal);
            },
            immediate: true,
        },
    },
    components: {
        InlineEditor,
        NotesDialog,
        RemarkPop,
    },
    data() {
        return {
            replaceFields: {
                children: 'children',
            },
            cardType: [],
            treeData: [],
            // 批注
            popVisible: false,
            // 笔记
            notesVisable: false,
            // 反馈
            feedVisable: false,
            popPos: {
                x: 0,
                y: 0,
            },
            cardInfo: {},
            selection: '',
            remarkId: '',

            item: {},
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    watch: {
        async data(v) {
            this.cardType = await this.$store.dispatch('card/getCardType');
            this.treeData = v;
        },
        keyword(keyword) {
            this.loopTreeData(this.treeData, item => {
                item.label = item.label.replaceAll(/<[^>]+>/g, '');
                if (keyword) {
                    item.label = item.label.replace(
                        new RegExp(keyword, 'g'),
                        "<span style='color:red;'>" + keyword + '</span>'
                    );
                }
            });
        },
    },
    methods: {
        goCard(card, num) {
            let width = document.documentElement.clientWidth;
            if (width < 1024) return;
            console.log(this.modelType);
            const routeUrl = this.$router.resolve({
                name: 'card-info',
                params: { id: card.Id },
                query: {
                    treeNum: num,
                    modelId: this.$route.params.id,
                    isFromModel: true,
                    type: this.modelType.value,
                },
            });
            window.open(routeUrl.href, '_blank');

            // const url =
            // window.open()
        },
        toggleCollect(IsCollection, card) {
            const user = this.userInfo.LoginName;
            if (!user) {
                this.$message.info('登录之后才能收藏，请先登录！');
                this.$store.commit('saveLoginDialog', true);
            } else {
                ToggleCollectCard(card.Id)
                    .then(res => {
                        const msg = IsCollection ? '取消成功' : '收藏成功';
                        this.loopTreeData(this.treeData, item => {
                            if (item.card.Id == card.Id) {
                                item.IsCollection = !item.IsCollection;
                            }
                        });
                        this.$message.success(msg);
                    })
                    .catch(e => e);
            }
        },
        // 循环树结构
        loopTreeData(list, callback) {
            (function doOneFloor(list) {
                if (Array.isArray(list)) {
                    for (let i = 0; i < list.length; i++) {
                        const item = list[i];
                        callback(item, i);
                        if (item.children.length > 0) {
                            doOneFloor(item.children);
                        }
                    }
                }
            })(list);
        },
        addNotes({ id, selection, start, end }) {
            console.log(1);
            this.loopTreeData(this.treeData, item => {
                if (item.card.Id == id) {
                    this.cardInfo = item;
                }
            });
            console.log(this.cardInfo);
            this.$emit('showNotes', {
                cardInfo: this.cardInfo,
                selection,
                start,
                end,
            });
        },

        // 批注弹窗显示
        showRemark({ e, id }) {
            this.remarkId = id;
            const { clientX, clientY } = e;
            this.popPos.x = clientX - 10;
            this.popPos.y = clientY + 20;
            this.popVisible = true;
        },
        closeRemark() {
            this.popVisible = false;
        },
        // 打开反馈弹窗
        addFeedback({ id, selection, start, end }) {
            this.loopTreeData(this.treeData, item => {
                if (item.card.Id == id) {
                    this.cardInfo = item;
                }
            });
            this.$emit('addFeedback', {
                cardInfo: this.cardInfo,
                selection,
                start,
                end,
            });
        },
        // 关闭反馈弹窗
        closeFeedDialog() {
            this.feedVisable = false;
        },
        selectItem(item) {
            this.item = item;
        },
    },
    created() {
        this.$store
            .dispatch('card/getCardType')
            .then(res => {
                this.cardType = res;
            })
            .catch(e => {
                console.log(e);
            });
    },
};
</script>
<style lang="less" scoped>
/deep/.ant-tree-switcher {
    display: none !important;
}

/deep/.ant-tree-child-tree {
    padding: 0 !important;
}

.tree_title {
    font-size: 16px;
    color: rgb(51, 51, 51);
    line-height: 2;
    white-space: pre-wrap;
    display: block;

    & > div {
        flex: 1;
    }
}

/deep/.ant-tree {
    font-size: 1em;

    & > li {
        padding: 0 !important;
        font-size: 0;

        span {
            height: auto;
            padding: 0;
            border: 0 !important;
        }
    }
}

.model_main {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;
    position: relative;
    transition: 0.5s;
    height: 100%;

    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 3px;
        background-color: #e5e5e5;
    }

    &::-webkit-scrollbar-thumb {
        width: 100%;
        border-radius: 3px;
        background-color: #f6a75c;
    }

    .cardName {
        border-radius: 15px;
        padding: 0 10px;
        color: #fff;
        margin-right: 15px;
        flex-shrink: 0;
    }

    .tree_title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
            font-size: 1em;
            color: rgb(51, 51, 51);
            line-height: 1.667;
            padding: 5px 10px;
            font-weight: bold;
            flex: 1;
        }

        .svg-icon {
            font-size: 18px;
            color: #999;
            transition: 0.3s;
            cursor: pointer;
            margin-left: 20px;
            flex-shrink: 0;
            outline: none;

            &.active {
                color: #f6a75c;
            }
        }

        &.type0 {
            .tree_title_header {
                background-color: #dce8f3;
                display: flex;
                align-items: flex-start;
            }
        }

        &.type1 {
            .tree_title_header {
                padding: 0;
            }
        }
    }

    .tree_content {
        padding: 15px 10px 20px;
        white-space: normal;
    }
}

/deep/.ant-tree-node-content-wrapper {
    width: 100%;

    &:hover,
    &.ant-tree-node-selected {
        background-color: transparent !important;
    }
}

@media (max-width: 1024px) {
    .model_main {
        padding-right: 0.15rem;

        .tree_title {
            font-size: 24px;

            .svg-icon {
                display: none;
            }
        }
    }
}
</style>
