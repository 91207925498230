
export const filterTreeData = (arr,ids) =>{
    return new Promise((resolve,reject) => {
        try{
            
            let newTree = [];
            (function doOneFloor (list , newTree) {
                if (Array.isArray(list)){
                    const len = list.length
                    for (let a = 0; a < len; a++){
                        const item = list[a]
                        if(ids.includes(Number(item.type))){
                            const { Content, IsCollection , card , label, num , status , type} = item
                            let obj = { Content, IsCollection , card , label, num , status , type ,children:[]}
                            newTree.push(obj)
                            if(item.children && item.children.length > 0){
                                doOneFloor (item.children , newTree[newTree.length-1].children)
                            }
                        } else{
                            if(item.children && item.children.length > 0){
                                doOneFloor (item.children , newTree)
                            }
                        }
                    }
                }
            })(arr,newTree)
            resolve(newTree)
        }
        catch(error) {
            reject(error)
        }
    })
}
// 循环树结构
export const transformTreeData = (arr) => {
    (function doOneFloor (list , parentLevel = '') {
        if (Array.isArray(list)) {
            const len = list.length
            let levelTit = 1
            for (let a = 0; a < len; a++) {
                const item = list[a]
                item.topic = item.label
                item.direction = 'right'
                item.id = !item.id ? item.num : item.id
                item.card.Content = item.card.Content || ''
                item.card.Tags = item.card.Tags || ''
                item.key =  item.card.Id,
                item.title = item.label
                if(!parentLevel){
                    item.levelTit = levelTit + '.'
                }else{
                    item.levelTit = '' +  parentLevel  + levelTit + '.'
                }
                levelTit = Number(levelTit)
                levelTit++
                if (item.children && item.children.length > 0) {
                    doOneFloor(item.children,item.levelTit)
                }
            }
        }
    })(arr)
    return arr
}

/**
 * config:{
 *  Name:用户名，
 *  Compony:网站名,
 *  Site:域名，默认取当前域名
 * }
 */
export const addImageWaterMask = async (imgUrl,config) => {
    // 合并参数
    let newconfig = {
        Name:config.Name || '',
        Company:config.Company || '',
        Site:config.Site || window.location.host
    }
    // 获取加载图片
    
    return new Promise(async (resolve,reject) => {
        try{
            let img = await onLoadImg(imgUrl)
            let width = img.width
            let height = img.height
            let canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            // 创建上下文
            let ctx = canvas.getContext('2d')
            // 添加背景色
            ctx.fillRect(0, 0, width, height)
            // // 选装添加水印
            ctx.translate(width / 2, height / 2)
            ctx.rotate((-45 * Math.PI) / 180)
            let font = width * 0.02 + 'px SimHei'
            ctx.font = font
            ctx.fillStyle = 'rgba(255,255,255,.5)' //设置填充绘画的颜色、渐变或者模式
            ctx.textAlign = 'center' //设置文本内容的当前对齐方式
            ctx.textBaseline = 'Middle' //设置在绘制文本时使用的当前文本基线
            ctx.fillText(newconfig.Name,0,0  )
            ctx.fillText(newconfig.Company, 0, width * 0.02 * 1.5)
            ctx.fillText(newconfig.Site, 0, width * 0.02 * 3)
            ctx.rotate((45 * Math.PI) / 180)
            ctx.translate(-width / 2, -height / 2)
            // ctx.translate(width / 5, -width / 2)
            ctx.drawImage(img, 0, 0, width, height)
            let aurl = canvas.toDataURL()
            resolve(aurl)
        } catch(error){
            reject(error)
        }
        
    })
} 
 function onLoadImg(img) {
    return new Promise((resolve, reject) => {
        try{
            let image = new Image();  
            image.src = img;  
            image.crossOrigin = 'anonymous';
            image.onload = () => {  
                resolve(image)
            }  
        }
        catch(error){
            reject(error)
        }
    })
}
// // 获取卡片类型
// export const getCardType = () => {
//     return new Promise((resolve, reject) => {
//         this.$store.dispatch('')
//     })
// }
