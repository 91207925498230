<template>
    <a-modal
        v-model="addVisable"
        centered
        :width="1000"
        :footer="null"
        class="addDialog"
        @cancel="close"
        :destroyOnClose="true"
        :closable="false"
        :confirm-loading="loading"
    >
        <div class="dialog_header">
            <!-- <h2>模型下载</h2> -->
            <h2>生成电子书</h2>
            <div class="close"><svg-icon icon-class="icon_close" @click.native="close"></svg-icon></div>
        </div>
        <div class="dialog_content">
            <div class="radio-irem" v-show="showContent">
                <!-- <div class="radio-header">模型内容</div> -->
                <div class="radio-header">电子书内容</div>
                <a-radio-group :options="contentGroup" v-model="form.modelType" />
            </div>
            <div class="radio-irem">
                <!-- <div class="radio-header">下载类型</div> -->
                <div class="radio-header">文化类型</div>
                <a-checkbox-group :options="typeGroup" v-model="form.downloadType" />
            </div>
            <div class="radio-irem">
                <!-- <div class="radio-header">是否下载思维导图</div> -->
                <div class="radio-header">是否包含思维导图</div>
                <a-radio-group :options="swdtGroup" :default-value="defaultSwdt" @change="swdtChange" />
            </div>
        </div>
        <div class="dialog_footer">
            <a-button class="confirm" :loading="loading" @click="confirmDownload">下载</a-button>
            <a-button class="cancel" @click="cancelDownload">取消</a-button>
        </div>
    </a-modal>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    props: {
        dialogVisable: {
            type: Boolean,
            default: false,
        },
        model:{
            type: Object,
            default: () => {
                return{
                    
                }
            },
        },
        loading:{
            type:Boolean,
            default:false
        },
        showContent:{
            type:Boolean,
            default:true
        }
    },
    computed: {
        ...mapState({
            modelType: (state) => state.model.modelType,
        }),
    },
    watch:{
        dialogVisable(v){
            this.addVisable = v
        },
        model:{
            handler(newVal,oldVal){
                this.form.modelType = newVal.name
                this.form.downloadType = []
            },
            immediate:true
        },
        modelType:{
            handler(newVal,oldVal){
                // this.form.downloadType = newVal
                newVal.forEach(element => {
                    const { name , value } = element
                    this.contentGroup.push({label:name,value:name})
                });
            },
            immediate:true
        }
    },
    data() {
        return {
            addVisable:false,
            // confirmLoading:false,
            form:{
                downloadType:[],
                modelType:''
            },
            contentGroup:[
            ],
            typeGroup:[
                { label: 'PDF', value: 'pdf' },
                { label: 'Word', value: 'word' },
                // { label: 'Html', value: 3 },
            ],
            swdtGroup:[
                { label: '是', value: true },
                { label: '否', value: false },
            ],
            defaultSwdt:false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        swdtChange(e) {
            this.defaultSwdt = e.target.value
        },
        async confirmDownload(){
            const { downloadType , modelType  } = this.form
            if(!modelType){
                this.$message.info('请选择模型内容！')
                return
            }
            if(downloadType.length == 0){
                this.$message.info('请选择下载类型！')
                return
            }
            
            // this.confirmLoading = true
            const form = {
                modelId:this.$route.params.id,
                downloadType:downloadType.join('*'),
                modelType:modelType
            }
            this.$emit('download', { form , isDownloadSwdt:this.defaultSwdt })
            return
            const { data } = await this.ExportWord(form)
            data.forEach((item,i) => {
                let link = document.createElement("a");
                link.href = item.url;
                link.download = item.name
                link.target = '_blank'
                // filePath=389acf72-87af-4b89-b24f-613cf9278d30.docx&fileName=%E6%B5%8B%E8%AF%95%E6%96%87%E6%A1%A/API/Export/
                setTimeout(() => {
                    link.click();
                },i*100) 
            })
            this.confirmLoading = false
            this.$emit('close')
        },
        cancelDownload(){
            this.defaultContent = 3
            this.defaultType = 0
            this.defaultSwdt = 0
            this.$emit('close')
        }
    },
  
}
</script>
<style lang="less" scoped>
:deep(.ant-modal-body){
    padding-left: 50px;
    padding-right: 50px;
}
.radio-irem{
    padding-top: 20px;
    padding-bottom: 10px;
    .radio-header{
        font-size: 18px;
        font-weight: bold;
        color: rgb(51, 51, 51);
        margin-bottom: 20px;
    }
}
:deep(.ant-checkbox){

}
:deep(.ant-checkbox-wrapper),
:deep(.ant-radio-wrapper){
    margin-right: 60px;
    font-size: 16px;
    color: rgb(85, 85, 85);
    margin-bottom: 10px;
    &:last-of-type{
        margin-right: 0;
    }
}
.dialog_content{
    padding-top: 0;
}
:deep(.ant-checkbox-input):focus + .ant-checkbox-inner,
:deep(.ant-checkbox):focus::after,
:deep(.ant-checkbox-wrapper):hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
:deep(.ant-checkbox-wrapper):hover .ant-checkbox-inner,
:deep(.ant-checkbox-wrapper):focus .ant-checkbox-inner,
:deep(.ant-checkbox-checked)::after,
:deep(.ant-checkbox):hover .ant-checkbox-inner{
    border-color: #f6a75c;
}
:deep(.ant-checkbox-checked) .ant-checkbox-inner{
    background-color: #f6a75c;
    border-color: #f6a75c;
    box-shadow: unset;
}
:deep(.ant-radio-wrapper):hover .ant-radio, 
:deep(.ant-radio):hover .ant-radio-inner, 
:deep(.ant-radio-input):focus + .ant-radio-inner{
    border-color: #f6a75c;
}
</style>