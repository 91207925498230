import { Base64 } from 'js-base64'
import request from '@/utils/request'
const baseURL = '/API/Export'

export const ExportWord = function (data) {
    return request({
        method: 'POST',
        url: '/API/Export/ExportWord',
        data:Base64.encode(JSON.stringify(data)),
        headers: {
            'Content-Type': 'application/json'
        },
    })
}